.social_button {
  display: flex;
}

.btn.button_secondary {
  margin-right: 15px;
}
.social_button .button.button_secondary:last-child {
  margin-right: 0;
}

.btn {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  background: #fff;
  border-radius: 5px;
  padding: 10px 15px;
  display: inline-block;
  min-width: 133px;
}
.btn span {
  margin-right: 5px;
}
.btn-secondary {
  color: #111827;
}
.btn-primary {
  background: #0F3A8C!important;
  color: #fff;
  display: block;
  text-align: center;
}
.btn.btn-secondary:hover, .btn.btn-primary:hover {
  opacity: .7;
}
.social_button {
  margin-bottom: 40px;
}

@media(max-width:767px){
  .btn.btn-secondary {
    margin-bottom: 15px;
  }
  .social_button {
    display: flex;
    flex-wrap: wrap;
  }
  .btn {
    width: 100%;
    text-align: center;
  }
  .btn.btn-secondary {
    margin-right: 0px;
    width: 100%;
  }
}

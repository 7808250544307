footer {
  background: #2C2C2C;
  color: #fff;
}
.footer_top {
  padding: 50px 0;
}
.footer_top ul {
  padding-left: 0 !important;
}

.partners {
  font-size: 12px;
}
.footer_top ul li a {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  margin-bottom: 20px;
}
.footer_top ul li a span {
  margin-right: 15px;
}
.footer_top ul li {
  margin-bottom: 15px;
}
.footer_heading {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

ul.social_icon {
  padding-left: 0 !important;
  display: flex;
  justify-content: space-between;
}
ul.social_icon li a i {
  font-size: 20px;
}

.footer_bottom {
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #FFFFFF;
}
.footer_bottom p {
  margin: 0;
  color: #C8C8C8;
}
.footer_top ul li a span img {
  width: 24px;
}

@media(max-width: 1024px){

  body ul.contact_icon .mb-5 {
    margin-bottom: 15px !important;
  }
  .footer_top .offset-md-2,
  .footer_top .offset-md-3 {
    margin-left: 0 !important;
  }
  .footer_top .col-md-2,
  .footer_top .col-md-3 {
    width: 33.33%;
  }
  ul.social_icon li:first-child {
    margin-left: 0;
  }
  ul.social_icon li {
    margin-left: 20px;
  }
  ul.social_icon {
    display: inline-flex;
  }
}
@media(max-width: 575px){
  .footer_top .col-md-2, .footer_top .col-md-3 {
    width: 100%;
    margin-left: 20px !important;
  }
  .footer_heading{
    margin: 20px 0 10px;
  }
  .hero_right_side {
    display: none;
  }
  .hero_leftside_wrap {
    margin-top: 0;
  }
  .col-md-6.responsive_hero{
    width: 100%;
  }

}

section.propertis_sec_wrap {
  padding: 30px 0;
}

.propertis_box {
  margin-bottom: 75px;
  padding-bottom: 5px;
}

.propertis_box:hover {
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
}

.propertis_img img,
.propertis_img span {
  margin-bottom: 24px;
}
.propertis_text_wrap h3,
.propertis_text_wrap h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #000000;
}
.propertis_text_wrap h3 {
  margin-bottom: 0;
}
.propertis_text_wrap h4 {
  margin-bottom: 30px;
}
.propertis_text_wrap p {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 13px;
}
.timer {
  background-color: #0F3A8C;
  border-radius: 6px;
  margin-bottom: 30px;
}

.timer ul {
  padding: 15px 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.timer ul li {
  color: #FFFFFF;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  text-align: center;
  width: 25%;
  border-right: 1px solid #fff;
}
.timer ul li span {
  display: block;
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
}
.timer ul li:last-child {
  border: none !important;
}
span.profit_scal {
  width: 40%;
  height: 12px;
  background: #1C76FD;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
span.profit_scal:after {
  content: '';
  width: 80%;
  height: 12px;
  background: #0F3A8C;
  position: absolute;
}
.box_footer {
  font-size: 12px !important;
  line-height: 15px !important;
  font-weight: 400;
  color: #000000;
  margin-top: 13px;
}

.pagination {
  display: flex;
  padding-left: 0;
  justify-content: center;
}

.pagination .page-link {
  border: none;
  color: #718087;
  font-size: 20px;
}
.pagination .page-item {
  display: inline-block;
  line-height: 23px;
  margin: 0 1px;
  border: 1px solid transparent;
}
.pagination .page-item.disabled .page-link {
  background: #c3c8cc;
  color: #fff;
}
.pagination .page-item:not(:first-child) .page-link {
  margin-left: 0px;
}

.page-item:last-child .page-link,
.page-item:first-child .page-link {
  border-radius: initial;
}

.pagination .page-item.active .page-link,
.pagination .page-item:hover .page-link {
  z-index: 3;
  color: #0F3A8C;
  background-color: transparent !important;
  border-color: #0d6efd;
}
.pagination .page-item:hover,
.pagination .page-item.active{
  border: 1px solid #0F3A8C;
}
.page-link {
  padding: 7px 15px !important;
}
.pagination .page-item .page-link:focus {
  outline:none !important;
}
.pagination .page-link:hover{
  background: none !important;
}
@media (max-width: 1024px){

  .propertis_text_wrap p {
    font-size: 18px;
  }
  .timer ul li {
    font-size: 18px !important;
  }
  .timer ul li span {
    font-size: 16px !important;
  }
}
@media (max-width: 875px){
  .timer ul li{
    width: 23%;
  }
}
@media(max-width: 767px){
  .propertis_img img{
    width: 100%;
  }
  .propertis_text_wrap p {
    font-size: 20px;
  }
  .heade_left_part ul{
    margin:0 ;
  }
}

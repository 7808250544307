section.error_wrap {
  padding: 100px 0;
}

section.error_wrap h3.error_heading {
  font-size: 60px;
  line-height: 72px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 30px;
}

.error_text_box {
  border: 1px solid #DADADA;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.error_text_wrap {
  padding: 110px;
}
.error_text_wrap {
  max-width: 60%;
  margin: 0 auto;
}
.error_text_wrap p {
  font-size: 16px;
  line-height: 26px;
}
.btn.error_primary a {
  background-color: #0F3A8C;
  border: #0F3A8C;
  border: 1px solid #0F3A8C;
  color: #fff;
}
.btn.error_primary a:hover {
  background: #4971FF;
  color: #fff;
  border: 1px solid #4971FF;
}
.btn.error_primary {
  margin: 30px auto;
  text-align: center;
}

@media(max-width: 1024px){
  .error_text_wrap {
    max-width: 70%;
  }
}
@media (max-width: 875px) {

  .error_text_wrap {
    max-width: 100%;
  }
}
@media(max-width: 575px){
  .error_text_wrap {
    padding: 60px;
  }

  section.error_wrap {
    padding: 10px 0!important;
  }
}

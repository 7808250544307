.social_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social_button {
  margin-bottom: 40px;
}

@media(max-width:767px){
  .social_button {
    display: flex;
    flex-wrap: wrap;
  }
}

#root {
  overflow: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --white: #ffffff;
  --off-white: rgba(255, 255, 255, 0.25);
  --black: #000000;
  --primary: #4a5064;
  --secondary: #2cd4d9;
  --light-blue: #b8cce0;
  --light-grey: #83899c;
  --grey: #a6a9af;
  --dark-rock-blue: #242831;
  --dark-green: #353d46;
  --dark: #423a61;
  --inputBck: #f6f6f9;
  --labelColor: #6f7486;
  --purple: #5333ed;
  --font-Manrope: "Manrope", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
  font-family: var(--font-Manrope) !important;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background: var(--white);
  color: var(--primary);
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.02em;
  font-family: var(--font-Manrope);
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
}

a:visited {
  color: var(--secondary);
}

body.mobileMenuActive {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-family: var(--font-Manrope);
  font-style: normal;
  font-weight: 800;
  color: var(--dark);
  margin: 0 0 3.2rem;
  letter-spacing: -0.02em;
}
h1 {
  font-size: 4.5rem;
  line-height: 5.8rem;
}
h2 {
  font-size: 3.2rem;
  line-height: 4rem;
}
h3 {
  font-size: 2.4rem;
  line-height: 3.4rem;
}
h4 {
  font-size: 1.8rem;
  line-height: 2.6rem;
}
h5 {
  font-size: 2rem;
  line-height: 2.4rem;
}
h6 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

@media (max-width: 767px) {
  h1 {
    font-size: 4.2rem;
  }
}

input {
  height: 100% !important;
}
label {
  padding-top: 1.2rem !important;
}

.helpIcon {
  position: relative;
}
.helpIcon img {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  gap: 2rem;
  margin-bottom: 1rem;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.5);
  right: 0px;
  font-size: 10px;
  width: 30px;
  height: 30px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 10px;
  color: black;
}

.swiper-button-prev {
  left: 0;
}

.swiper-wrapper {
  width: 100vw;
}

.swiper-button-disabled {
  display: none;
}

.swiper-pagination {
  display: none;
}

.lastPriceBox {
  margin-top: 4rem;
  border-top: 1px dashed #b8cce0;
  padding: 2rem 2rem;
}

.apartmentDetails {
  position: relative;
  padding-bottom: 10rem;
}
.apartmentDetails::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 100vw;
  height: 100%;
  background: rgba(198, 200, 215, 0.15);
  clip-path: polygon(0% 24.3%, 100% 0%, 100% 75.8%, 0% 100%);
  pointer-events: none;
  z-index: -1;
  margin-left: -50vw;
}

@media (max-width: 430px) {
  .helpIcon img {
    display: none;
  }
  .helpIcon {
    display: block !important;
  }
  .lastPriceBox {
    padding: 0%;
    padding-top: 2rem;
    margin-top: 2rem;
  }
  .lastPriceBox div {
    display: block !important;
  }

  .lastPriceBox div {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .helpIcon {
    display: none;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


section.invest_main {
  padding: 100px 0;
}
.left_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.left_top h2 {
  font-size: 21px;
  line-height: 26px;
  color: #000;
  font-weight: 600;
}
.top_leftpart p {
  color: #718087;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

section.invest_main .right_border {
  border-right: 1px solid #C4C4C4;
}
.invest_border {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border: 1px solid #DADADA;
  padding: 60px 0;
}
.right_wrap,
.left_wrap {
  padding: 0 90px;
}
.amount {
  position: relative;
  margin-bottom: 35px;
}
.amount p {
  display: flex;
  align-items: center;
}
.amount p input {
  color: #242426;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  padding-bottom: 5px;
  border: none;
  width: 60%;
  margin-left: 0;
  padding-left: 0;
}
.amount .form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.amount p:after {
  content: '';
  width: 60%;
  height: 2px;
  background: #718087;
  position: absolute;
  bottom: 0;
  left: 0;
}
.amount p span {
  font-weight: 600;
  font-size: 18px;
  color: #718087;
}
.revenus {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.revenus p {
  font-size: 18px;
  line-height: 28px;
  color: #242426;
  font-weight: 600;
}

.payment_method ul {
  border: 1px solid #DADADA;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  max-width: 400px;
  position: relative;
}
.payment_method ul li label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DADADA;
  padding: 10px 15px;
}
.payment_method ul li span input {
  margin-right: 10px;
}
.payment_method ul li:last-child {
  border: none !important;
}
.payment_method ul li span {
  font-size: 16px;
  line-height: 19px;
  color: #111111;
  font-weight: 500;
}
.button.button_primary {
  margin-top: 30px;
}
@media (max-width:1024px){
  .amount p:after,
  .amount p input{
    width: 22%;
  }
}
@media (max-width: 767px) {
  section.invest_main .right_border{
    border: none;
  }
  .right_wrap {
    padding: 50px 90px;
  }
  .revenus,
  .payment_method ul{
    max-width: 100%;
  }
  .payment_method h2{
    text-align: center;
  }
  h2.heading {
    font-size: 50px;
    line-height: 60px;
  }
  .amount p:after, .amount p input {
    width: 13%;
  }
}
@media(max-width: 575px){
  h2.heading {
    font-size: 30px!important;
    line-height: 44px!important;
  }
  h2.heading span {
    width: 25px !important;
    height: 25px !important;
  }
  .amount p:after, .amount p input {
    width: 50%;
  }

  .invest_border {
    padding: 20px 0;
  }

  .left_wrap, .right_wrap {
    padding: 0 10px;
  }

  section.invest_main {
    padding-top: 0;
    padding-bottom: 10px;
  }
}


.heading {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  padding-left: 0;
}
h2.heading span {
  width: 45px;
  height: 45px;
  background-color: #0F3A8C;
  display: inline-block;
}
.second_heading {
  font-family: 'Manrope', sans-serif;
  color: #718087;
  font-size: 25px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 40px;
}
section.invest_main h4 {
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #165EDC;
  font-weight: 600;
}
.payment_method h2 {
  font-family: 'Manrope', sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  color: #718087;
}
h3.eror_heading {
  font-size: 60px;
  line-height: 72px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 30px;
}

.invest_wrapper {
  margin-top: 30px;
}

.invest_main .infos {
  display: flex;
  justify-content: space-between;
}

.invest_main .infos .amount {
  padding-top: 0;
  line-height: initial;
}

.logo img {
  max-height: 46px;
}

.progress_campaign {
  display: flex;
  align-items: center;
}

.progress_campaign .progress {
  width: 40%;
  border-radius: initial;
  height: 0.8rem;
  background-color: #1C76FD;
}

.progress_campaign .progress .progress-bar {
  background-color: #0F3A8C;
}
.progress_campaign .data {
  display: flex;
  margin-left: 15px;
}


section.navigation nav.navbar {
  padding: 17px 0;
}
.navigation .nav-item a {
  font-size: 14px;
  line-height: 20px;
  color: #000!important;
  font-weight: 600;
  display: inline-block;
}
.navigation .nav-link {
  font-size: 14px;
  line-height: 20px;
  color: #000!important;
  font-weight: 600;
  display: inline-block;
}

.navigation .nav-link.active {
  text-decoration: underline;
}
.navigation .navbar-collapse {
  justify-content: end;
}
.navbar-nav .nav-item {
  margin-left: 40px;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  border: none;
  background-color: #FAFAFA;
  box-shadow: 0 5px 35px 0 rgb(27 37 44 / 25%);
  border-radius: 5px;
}

ul.dropdown-menu {
  border-color: #f1e8e8;
  margin: 0;
  padding: 10px 0;
  border-radius: 4px;
}

@media (max-width: 767px) {
  section.navigation button.navbar-toggler {
    background: #ffffff;
  }
  section.navigation .navbar-toggler .fa-bars {
    color: #fff;
    font-size: 25px;
  }
}

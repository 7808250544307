.diagnostics {
  --border-radius-buttons: 0;
}

.diagnostics .cell.new.level-1 {
  background-color: #009c6d;
}

.diagnostics .cell.new.level-2 {
  background-color: #50b054;
}

.diagnostics .cell.new.level-3 {
  background-color: #78bd75;
}

.diagnostics .cell.new.level-4 {
  background-color: #f3e715;
}

.diagnostics .cell.new.level-5 {
  background-color: #efb40c;
}

.diagnostics .cell.new.level-6 {
  background-color: #eb8335;
}

.diagnostics .cell.new.level-7 {
  background-color: #d7231f;
}

.diagnostics .cell {
  color: #fff;
  height: 20px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  width: 25px;
}

.diagnostics .cell.focus .score {
  display: inline-block!important;
}

.ges-diag .cell.new.level-1 {
  background-color: #a4dbf8;
}
.ges-diag .cell.new.level-2 {
  background-color: #8bb4d2;
}
.ges-diag .cell.new.level-3 {
  background-color: #7792b1;
}
.ges-diag .cell.new.level-4 {
  background-color: #5e6e8e;
}
.ges-diag .cell.new.level-5 {
  background-color: #4d5070;
}
.ges-diag .cell.new.level-6 {
  background-color: #3a3551;
}
.ges-diag .cell.new.level-7 {
  background-color: #271b35;
}

@media (max-width: 10230px) {
  .diagnostics .cell.focus {
    border: 2px solid #000;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    height: 40px;
    line-height: 38px;
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 2px;
    position: relative;
    width: 40px;
    z-index: 1;
  }

  .diagnostics .cell {
    border-radius: var(--border-radius-buttons);
    color: transparent;
    display: inline-block;
  }

  .diagnostics .cell[data-v-3a91efe1] {
    border-radius: var(--border-radius-buttons);
    color: transparent;
    display: inline-block;
  }

  .diagnostics .cell {
    color: #fff;
    height: 20px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    width: 25px;
  }

  .diagnostics {
    height: 60px;
    opacity: .8;
  }

  .diagnostics .cell.focus .score {
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 5px;
    color: #000;
    display: block;
    font-size: 16px;
    font-weight: 700;
    left: -12px;
    line-height: 35px;
    margin-top: -7px;
    padding: 0;
    position: absolute;
    right: -12px;
  }

  .diagnostics .cell.focus .score .value {
    position: relative;
    top: -5px;
  }

  .diagnostics .cell.focus .score .unit {
    font-size: .5rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 11px;
    width: 100%;
  }
}

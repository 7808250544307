.social_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social_button {
  margin-bottom: 40px;
}

.social_connection form input + span
{
  cursor: pointer;
}

.social_connection form input,
.social_connection form input + span
{
  border: none;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  color: #000;
  background: #fff;
}
.social_connection form input:focus{
  outline: none;
  border: none;
  box-shadow: none;
}
.social_connection form input::placeholder {
  color: #817777;
  font-weight: 600;
}

@media(max-width:767px){
  .social_button {
    display: flex;
    flex-wrap: wrap;
  }
}

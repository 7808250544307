ul.head_nav {
  padding: 0;
}
.heade_left_part li {
  font-family: 'Manrope', sans-serif;
  display: inline-block;
  color: #0832CA;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600 !important;
}
.head_toppart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.heade_right_part .dropdown .nav-link {
  font-size: 16px;
  line-height: 19px;
  color: #111111;
  font-weight: 500 !important;
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 12px;
}
.heade_right_part {
  display: flex;
  align-items: center;
}
.heade_right_part span {
  font-family: 'Manrope';
  margin-right: 10px;
  color: #000000;
  font-size: 16px;
  line-height: 19px;
}

.breadcrumb {
  margin: 0;
}
